<template>
  <div>
    <van-nav-bar title="作业详情" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <section class="van-doc-demo-section">
      <div class="van-coupon van-coupon--disabled">
        <div style="padding:20px">
          <van-row>
            <van-col span="8">学习项目：</van-col>
            <van-col span="16">{{infoData.LearningProjectName}}</van-col>
          </van-row>
          <van-row>
            <van-col span="8">任务名称：</van-col>
            <van-col span="16">{{infoData.Title}}</van-col>
          </van-row>
          <van-row>
            <van-col span="8">作业要求：</van-col>
            <van-col span="16">{{infoData.Requirement}}</van-col>
          </van-row>
          <van-row>
            <van-col span="8">发起时间：</van-col>
            <van-col span="16">{{infoData.LaunchTime}}</van-col>
          </van-row>
        </div>
      </div>
      <div style="padding:20px" v-if="infoData.IsEvaluate ==false">
        <van-uploader :after-read="onRestUpload" accept="video/*" :max-size=1024000000>
          <!--@click-upload-->
          <van-button icon="plus" type="primary">{{uploadtext}}</van-button>
        </van-uploader>
        <div> 上传完成后会进行视频压缩，需稍后在进行预览</div>
      </div>

      <div class="van-coupon van-coupon--disabled" @click="videoDetail" v-show="uploadshow">
        <div class="van-coupon__content">
          <div class="van-coupon__head">
            <h2 class="van-coupon__amount">{{videoName}}</h2>
            <p class="van-coupon__condition">{{infoData.SubmitTime}}</p>
          </div>
          <div class="van-coupon__body" v-if="infoData.IsEvaluate">
            <p class="messg_success">已批改</p>
          </div>
          <div class="van-coupon__body" v-else>
            <p class="messg_warning">未批改</p>
          </div>
        </div>
      </div>
    </section>
    <van-popup v-model="loading" :close-on-click-overlay=false :style="{'height':'80px','width':'200px','line-height':'80px'}">
      <div style="text-align:center;">正在上传中，请耐心等待....</div>
    </van-popup>
    <van-dialog v-model="videoshow" title="" width="90%">
      <video :src="videoPath" controls="controls" width="100%">
        your browser does not support the video tag
      </video>

    </van-dialog>
  </div>

</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      jobTaskID: "",//作业ID
      studentID: "", // 学生ID
      infoData: [],
      videoName: "",
      videoPath: "",
      videoshow: false,
      uploadshow: false,
      loading: false,
      uploadtext: "上传视频",
      lodingVisible: false
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/JobTask/StudentJobTask?studentID=' + this.studentID + "&jobTaskID=" + this.jobTaskID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data)
        if (data.code == 200) {
          this.infoData = data.data;
          if (this.infoData.VideoPath != null && this.infoData.VideoPath != "") {
            this.uploadtext = "重新上传视频";
            this.uploadshow = true;

            this.videoPath = this.infoData.VideoPath;
            let len = this.infoData.VideoPath.split('/').length;
            this.videoName = this.infoData.VideoPath.split('/')[len - 1];

          }
        }
      });
    },
    // 点击左上角返回
    onClickLeft() {
      this.$router.push({ name: "work" });
    },
    onClickRight() { },//这个方法不要删，上面导航返回控件必须带的
    videoDetail() {  //查看视频
      this.videoshow = true;
    },
    onRestUpload(file) {

      if (this.infoData.IsEvaluate) {
        this.$dialog({ message: "已评分的视频作业无法在进行修改" });
        return;
      }

      if (this.infoData.VideoPath != null && this.infoData.VideoPath != "") {
        this.$dialog.confirm({
          title: '提示',
          message: '重新上传视频会覆盖已上传文件',
        })
          .then(() => {
            // on confirm
            let formData = new FormData(); // 为上传文件定义一个formData对象
            formData.append("file", file.file);
            this.loading = true;
            //$_uploadAction为请求方法，请使用自己项目中的axiox请求
            //这里需要注意的是'Content-Type'必须为'application/form-data',
            this.$axios.post("/api/JobTask/StudentVideoUpload", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
              var data = JSON.parse(res.data);
              if (data.code == 200) {
                if (data.data == "0") {
                  this.loading = false;
                  this.$dialog({ message: "上传失败，仅支上传视频文件" });
                  return;
                }
                else {
                  let para = {
                    StudetnID: this.studentID,
                    JobTaskID: this.jobTaskID,
                    VideoPath: data.data,
                  };
                  //提交上传视频路径和上传人信息
                  this.$axios.post('/api/JobTask/StudentJobTaskSubmit', para).then(res => {
                    this.loading = false;
                    var data = JSON.parse(res.data);
                    console.log(data)
                    if (data.code == 200) {
                      this.infoData = data.data;
                      this.uploadtext = "重新上传视频";
                      this.uploadshow = true;

                      this.videoPath = this.infoData.VideoPath;
                      let len = this.infoData.VideoPath.split('/').length;
                      this.videoName = this.infoData.VideoPath.split('/')[len - 1];
                      this.$dialog({ message: "视频上传完成，视频将进行压缩，稍后请刷新在进行查看" });
                    }
                  });
                }
              } else {
                this.loading = false;
                this.$dialog({ message: data.info });
              }
            }).catch(function (error) {

              console.log(error);

            });
          })
          .catch(() => {
            // on cancel

          });
      }
    }
  },
  mounted() {
    this.jobTaskID = this.$route.query.jobTaskID;
    this.studentID = Cookies.get('studentID');

    if (this.studentID == null) {
      this.$router.push({ name: "login" });
    }

    this.initData();
  },
}
</script>

<style>
.van-doc-demo-section {
  box-sizing: border-box;
  min-height: calc(100vh - 56px);
  padding-bottom: 20px;
}

.van-coupon {
  margin: 12px 12px 6px 12px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgb(222, 219, 219);
}

.van-coupon__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 84px;
  padding: 14px 0;
  color: #323233;
}

.van-coupon--disabled .van-coupon__head {
  color: inherit;
}
.van-coupon__head {
  position: relative;
  min-width: 300px;
  padding: 0 8px 0 15px;
  color: #ee0a24;
}

.van-coupon__body {
  text-align: right;
}
.messg_warning {
  color: rgba(237, 122, 112, 1);
}

.messg_success {
  color: rgba(42, 204, 155, 1);
}

.van-coupon__amount {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.van-button--primary {
  width: 140px;
  color: #fff;
  background-color: #099df3;
  border: 1px solid #099df3;
}

.van-row {
  padding: 5px 0 5px 0;
}

.van-col--8 {
  font-weight: 100;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>